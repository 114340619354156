@import url("https://fonts.googleapis.com/css2?family=Lemon&family=McLaren&display=swap");

body {
  font-family: "McLaren", sans-serif;
  color: #fff;
  background-color: #000;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lemon", serif;
}

.bg-about {
  background: rgba(115, 0, 255, 0.15);
}

html {
  scroll-behavior: smooth;
}

.text-gradient {
  background: linear-gradient(180deg, #fa04ff 0%, #ff5c00 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

a {
  color: inherit;
  cursor: pointer;
}

img {
  width: 100%;
}

.text-gradient {
  background: linear-gradient(180deg, #00ffe0 0%, #fa04ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.box-shadow-1 {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.App {
  overflow: hidden;
  background-image: url("./img/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
